
import { useEffect, useState } from 'react';
import { StationService } from '../../services/StationService';
import { Button } from '../shared/Button';
import { InputRange } from '../shared/InputRange';
import './modal-config.css';
import { CameraController } from "./CameraController";
import { Col, Row } from "react-bootstrap";
import { CameraView } from './CameraView';

export const CameraConfig = ({ isShow, setIsShow, stationId, setIsClose }) => {
    const stationService = new StationService();
    const [streamUrl, setStreamUrl] = useState('');
    const [ws, setWs] = useState('');
    const [ip, setIp] = useState('');
    const [port, setPort] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (isShow) {
            (async () => {
                const schedule = await stationService.get('/api/station/v1/cameraUrl?stationId=' + stationId);
                if (schedule?.data?.code === 0) {
                    setStreamUrl(schedule?.data?.data?.url);
                    setWs(schedule?.data?.data?.wsUrl);
                    setIp(schedule?.data?.data?.ip);
                    setPort(schedule?.data?.data?.port);
                    setUsername(schedule?.data?.data?.username);
                    setPassword(schedule?.data?.data?.password);
                }

            })()
        }
    }, [stationId, isShow]);

    const handleSave = async () => {
        // setIsEdit(!isEdit);
        // if (!isEdit) return;

        // const data = await stationService.updateStreamCameraUrl(stationId, 1, streamUrl);
        const data = await stationService.post('/api/station/v1/cameraUrl', {
            stationId: stationId,
            wsLink: ws,
            username: username,
            password: password,
            ip: ip,
            port: port,
            streamUrl: streamUrl
        });

        // setIsShow(false);
    }

    return (
        <div className={isShow ? 'modal-overlap' : 'd-none'}>
            <div className="activity-tab modal-content">
                <div className="modal-hero">Cài đặt Camera</div>
                <div className="activity-header py-2">
                    <nav className="activity-text mr-3">Camera URL : </nav>
                    <input
                        className="item-input-camera-url"
                        type={"text"}
                        value={streamUrl}
                        onChange={(value) => setStreamUrl(value.target.value)}
                    />
                </div>
                <div className="activity-header py-2">
                    <nav className="activity-text mr-3">Camera Stream : </nav>
                    <input
                        className="item-input-camera-url"
                        type={"text"}
                        value={ws}
                        onChange={(value) => setWs(value.target.value)}
                    />
                </div>
                <div className="activity-header py-2">
                    <nav className="activity-text mr-3">Username : </nav>
                    <input
                        className="item-input-camera-url"
                        type={"text"}
                        value={username}
                        onChange={(value) => setUsername(value.target.value)}
                    />
                    {/* <Button text={isEdit ? "Áp dụng" : "Chỉnh sửa"} onClick={handleSave} /> */}
                </div>
                <div className="activity-header py-2">
                    <nav className="activity-text mr-3">Password : </nav>
                    <input
                        className="item-input-camera-url"
                        type={"text"}
                        value={password}
                        onChange={(value) => setPassword(value.target.value)}
                    />
                    {/* <Button text={isEdit ? "Áp dụng" : "Chỉnh sửa"} onClick={handleSave} /> */}
                </div>
                <div className="activity-header py-2">
                    <nav className="activity-text mr-3">IP/Host : </nav>
                    <input
                        className="item-input-camera-url"
                        type={"text"}
                        value={ip}
                        onChange={(value) => setIp(value.target.value)}
                    />
                    {/* <Button text={isEdit ? "Áp dụng" : "Chỉnh sửa"} onClick={handleSave} /> */}
                </div>
                <div className="activity-header py-2">
                    <nav className="activity-text mr-3">Port : </nav>
                    <input
                        className="item-input-camera-url"
                        type={"text"}
                        value={port}
                        onChange={(value) => setPort(value.target.value)}
                    />
                    {/* <Button text={isEdit ? "Áp dụng" : "Chỉnh sửa"} onClick={handleSave} /> */}
                </div>

                <div style={{ justifyContent: 'center', margin: '10px 0 0 0', gap: "16px", display: "flex", flexDirection: "row", width: "100%" }}>
                    <Button
                        onClick={() => {
                            setIsShow(false);
                        }}
                        text={"Đóng lại"} />

                    <Button
                        onClick={() => {
                            handleSave();
                        }}
                        text={"Lưu lại"} />
                </div>
            </div>
        </div >
    );
}
