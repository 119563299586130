import { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { IcArrowBottom, IcArrowBottomLeft, IcArrowBottomRight, IcArrowLeft, IcArrowRight, IcArrowTop, IcArrowTopLeft, IcArrowTopRight, IcScroll, IcZoomIn, IcZoomOut } from '../../assets';
import { StationService } from '../../services/StationService';

const CameraControlType = {
    "1-2": "DIR_0",
    "0-2": "DIR_45",
    "0-1": "DIR_90",
    "0-0": "DIR_135",
    "1-0": "DIR_180",
    "2-0": "DIR_225",
    "2-1": "DIR_270",
    "2-2": "DIR_315",
    "1-1": "ROTATE",
    "zoomin": "ZOOM_IN",
    "zoomout": "ZOOM_OUT",
    "run": "PRESET",
}

const d = [[<IcArrowTopLeft />, <IcArrowTop />, <IcArrowTopRight />], [<IcArrowLeft />, <IcScroll />, <IcArrowRight />], [<IcArrowBottomLeft />, <IcArrowBottom />, <IcArrowBottomRight />]]

export const CameraController = ({ stationId, schedulesList }) => {
    const [scheduleSelected, setScheduleSelected] = useState("");
    const [schedules, setSchedules] = useState(schedulesList);
    console.log(schedulesList)

    useEffect(() => {
        setSchedules(schedulesList)
    }, [schedulesList])

    // const [listScheul]

    // Initialize state for the matrix
    const [matrix, setMatrix] = useState([
        ['X', 'X', 'X'],
        ['X', 'X', 'X'],
        ['X', 'X', 'X']
    ]);

    // Function to handle button click
    const handleButtonClick = (rowIndex, colIndex) => {
        // Create a copy of the matrix
        const updatedMatrix = [...matrix];
        // Toggle the value at the clicked button's position
        updatedMatrix[rowIndex][colIndex] = updatedMatrix[rowIndex][colIndex] === 'X' ? 'X' : 'X';
        // Update the state with the modified matrix
        setMatrix(updatedMatrix);

        new StationService().post('/api/station/v1/controlCamera', {
            stationId: stationId,
            type: CameraControlType[`${rowIndex}-${colIndex}`]
        })
    };

    function runSchedule() {
        console.log(CameraControlType['run'])
        new StationService().post('/api/station/v1/controlCamera', {
            stationId: stationId,
            type: CameraControlType['run'],
            preset: scheduleSelected
        })
    }

    return (
        <div style={{ margin: "auto" }}>
            <div className="modal-hero" style={{ margin: "5px" }}>Điều khiển</div>
            <table style={{ margin: "auto" }}>
                <tbody>
                    {matrix.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, colIndex) => (
                                <td key={`${rowIndex}` + "-" + `${colIndex}`}>
                                    <Button style={{ width: '50px', height: '50px' }} onClick={() => handleButtonClick(rowIndex, colIndex)}>{d[rowIndex][colIndex]}</Button>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{
                width: '100%',
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "8px",
                padding: "12px"
            }}>
                <Button
                    onClick={() => {
                        new StationService().post('/api/station/v1/controlCamera', {
                            stationId: stationId,
                            type: CameraControlType[`zoomout`]
                        })
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: 'center',
                        padding: "12px",
                    }}>
                    <IcZoomOut />
                </Button>
                <Button
                    onClick={() => {
                        new StationService().post('/api/station/v1/controlCamera', {
                            stationId: stationId,
                            type: CameraControlType[`zoomin`]
                        })
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: 'center',
                        padding: "12px",
                    }}>
                    <IcZoomIn />
                </Button>
            </div>

            {/* Danh sách chiến lược */}
            <div style={{
                width: '100%',
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "8px",
                padding: "12px"
            }}>
                <select
                    value={scheduleSelected}
                    onChange={(event) => {
                        console.log(event);
                        setScheduleSelected(event.target.value)
                    }}
                >
                    {
                        schedules && schedules.map((v, i) => (
                            <option key={`${v?.token ?? ""}-${i}`} value={`${v?.token ?? ""}`}>{v?.name ?? ""}</option>
                        ))
                    }
                </select>
                <Button
                    onClick={() => {
                        runSchedule()
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: 'center',
                        padding: "12px",
                    }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_2_95)">
                            <path d="M18 12L9 18V6.00002L18 12ZM12 0.0022583C5.38146 0.0022583 0.00222778 5.38149 0.00222778 12C0.00222778 18.6185 5.38146 23.9978 12 23.9978C18.6185 23.9978 23.9978 18.6185 23.9978 12C23.9978 5.38149 18.6185 0.0022583 12 0.0022583ZM12 1.4978C17.8079 1.4978 22.5022 6.19217 22.5022 12C22.5022 17.8079 17.8079 22.5022 12 22.5022C6.19212 22.5022 1.49777 17.8079 1.49777 12C1.49777 6.19217 6.19212 1.4978 12 1.4978Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_2_95">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </Button>
            </div>

        </div>
    );

}