
import { useEffect, useState } from 'react';
import { StationService } from '../../services/StationService';
import { Button } from '../shared/Button';
import { InputRange } from '../shared/InputRange';
import './modal-config.css';
import { CameraController } from "./CameraController";
import { Col, Row } from "react-bootstrap";
import { CameraView } from './CameraView';

export const CameraViewDetail = ({ isShow, setIsShow, stationId, setIsClose }) => {
    const stationService = new StationService();
    const [form, setForm] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [streamUrl, setStreamUrl] = useState('');
    const [wsUrl, setWSUrl] = useState(null);
    const [schedules, setSchedule] = useState([]);

    useEffect(() => {
        if (isShow) {
            (async () => {
                const data = await stationService.getStreamCameraUrl(stationId, 1);

                const schedule = await stationService.post('/api/station/v1/requestControlCamera', {
                    stationId: stationId
                });
                if (schedule?.data?.code === 0) {
                    setSchedule(schedule?.data?.data?.presets ?? [])
                }


                // setForm(data.data);
                setStreamUrl(data.data?.url)
                setWSUrl(data.data.wsUrl);
            })()
        }
    }, [stationId, isShow]);

    const handleSave = async () => {
        setIsEdit(!isEdit);
        if (!isEdit) return;

        const data = await stationService.updateStreamCameraUrl(stationId, 1, streamUrl);

        // setIsShow(false);
    }

    return (
        <div className={isShow ? 'modal-overlap' : 'd-none'}>
            <div className="activity-tab modal-content">
                <div className="modal-hero">Giám sát Camera</div>
                <div className="activity-header py-2">
                    <nav className="activity-text mr-3">Camera URL : </nav>
                    <input
                        className="item-input-camera-url"
                        type={"text"}
                        readOnly={!isEdit}
                        value={streamUrl}
                        onChange={(value) => setStreamUrl(value.target.value)}
                    />
                    {/* <Button text={isEdit ? "Áp dụng" : "Chỉnh sửa"} onClick={handleSave} /> */}
                </div>
                <Row style={{ padding: '1em', height: '400px', justifyContent: "space-between" }}>
                    <Col xs={4}>
                        <CameraController stationId={stationId} schedulesList={schedules} />
                    </Col>
                    <Col xs={8}>
                        {/*<div style={{margin: 'auto'}}>*/}
                        {/*    <iframe width="560" height="315" src="https://www.youtube.com/embed/Ve_1XZkG65U?si=lkvKytAIy3cMOiGU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>*/}
                        {/*</div>*/}
                        {/* component cũ */}
                        {/* <JsmpegPlayer videoUrl={wsUrl} /> */}
                        {/* component mới của Thuận 25/04/2024 */}
                        <CameraView style={{ width: '480px', height: "320px", }} preFix={"detail-"} stationId={stationId} />
                    </Col>
                </Row>

                <div style={{ justifyContent: 'center', gap: "16px", display: "flex", flexDirection: "row", width: "100%" }}>
                    <Button
                        onClick={() => {
                            setIsEdit(false);
                            setIsShow(false);
                        }}
                        text={"Đóng lại"} />

                    <Button
                        onClick={() => {
                            setIsEdit(false);
                            setIsShow(false);
                            setIsClose(false);
                        }}
                        text={"Tắt camera"} />
                </div>
            </div>
        </div >
    );
}
