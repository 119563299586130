export const config = {
  version: "2.1.0",
  os: "win", // lin/mac/win
  // HOST_WS: 'ws://192.168.5.188:8443/websocket',
  // HOST_HTTP: 'http://192.168.5.188:8443',
  // HOST_WS: 'ws://192.168.1.41:8443/websocket',
  // HOST_HTTP: 'http://192.168.1.41:8443',
  // HOST_WS: 'ws://192.168.0.103:8443/websocket',
  // HOST_HTTP: 'http://192.168.0.103:8443',
  // HOST_WS: 'wss://traffic.sdkd.com.vn/websocket',
  HOST_WS: "wss://api.traffic.sdkd.com.vn/websocket",
  HOST_HTTP: "https://traffic.sdkd.com.vn",
  // HOST_WS: 'wss://traffic.sdkd.com.vn/websocket',
  // HOST_HTTP: 'https://traffic.sdkd.com.vn',
  // HOST_WS: 'wss://dev.hue-traffic.sitech.com.vn/websocket',
  // HOST_HTTP: 'https://dev.hue-traffic.sitech.com.vn',
  MAP_KEY: "AIzaSyBzbe28QnGXSBYnz68LLYgLhTpvmEPNopY",
  // MAP_KEY: "AIzaSyB9K1X6XsKGsSXi9lSIcrvaJCi-4kKALj8"
};
// AIzaSyCvBB_TxuyLSHIXJsAhrDAK86ooki - ow2E;
// AIzaSyC1WFY7kqYrer5FxiXyCramtJ1vKCy9Dwg;
