
import { useEffect, useState } from 'react';
import { StationService } from '../../services/StationService';
import { Button } from '../shared/Button';
import { InputRange } from '../shared/InputRange';
import './modal-config.css';

export const ModalConfig = ({ isShow, setIsShow, stationId }) => {
    const stationService = new StationService();
    const [form, setForm] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [totalTime, setTotalTime] = useState(0);

    useEffect(async () => {
        if (isShow) {
            const data = await stationService.getCurrentCycleForm(stationId);
            setForm(data.data);
            setTotalTime(data.data.total);
        }
    }, [stationId, isShow]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleUpdateTime(totalTime);
        }, 800);

        return () => clearTimeout(timer);
    }, [totalTime])

    const handleChange = (idx, obj) => {
        form.phaseCounters[idx].startGreen = obj.startGreen;
        form.phaseCounters[idx].greenTime = obj.greenTime;
        form.phaseCounters[idx].yellowTime = obj.yellowTime;
        setForm({...form});
    }

    const handleSave = async () => {
        setIsEdit(!isEdit);
        if (!isEdit) return;

        const result = await stationService.setCycleTime({
            stationId: stationId,
            numPhase: form.nphase,
            total: form.total,
            phaseCounters: form.phaseCounters
        })
        setIsShow(false);
    }

    const handleChangeTime = (e) => {
        const minPhase = 1;
        if (parseInt(e.target.value || 0) < minPhase) {
            setTotalTime(minPhase);
        } else if (parseInt(e.target.value) >= minPhase) {
            setTotalTime(parseInt(e.target.value));
        }
    }

    const getMaxPhase = () => {
        return Math.max(...form?.phaseCounters?.map(phase => phase.startGreen + phase.greenTime + phase.yellowTime));
    }

    const handleUpdateTime = (totalTime) => {
        if (form) {
            form.total = totalTime;
            for (let i = 0; i < form.phaseCounters?.length; i++) {
                form.phaseCounters[i].total = totalTime;                
                if (totalTime < getMaxPhase()) {
                    form.phaseCounters[i].startGreen = 0;
                    form.phaseCounters[i].greenTime = totalTime - 3;
                    form.phaseCounters[i].yellowTime = 3;
                }
            }
            setForm({...form});
        }
    }

    return (
        <div className={isShow ? 'modal-overlap' : 'd-none'}>
            <div className="activity-tab modal-content">
                <div className="modal-hero">Cấu hình biểu mẫu</div>
                <div className="activity-header py-2 justify-content-start">
                    <nav className="activity-text mr-3">Số pha : <span className="color-green">{form?.nphase}</span></nav>
                    <nav className="activity-text">Tổng chu kỳ :{' '}
                        {isEdit ? <>
                            <input type="number" className="schedule-input" value={totalTime} onChange={handleChangeTime} onBlur={() => handleUpdateTime(totalTime)} />
                            <span className="color-green"> giây</span>
                        </> : <span className="color-green">{form?.total} giây</span>}
                    </nav>
                </div>
                <div className="activity-content config-content scrollbar-homepage scrollbar-custom-homepage">
                    {form?.phaseCounters?.map((phase, idx) => (
                        <div className="activity-item">
                            <span className="text-medium">Pha {idx + 1}</span>
                            <InputRange
                                isDisable={!isEdit}
                                greenTime={phase.greenTime}
                                yellowTime={phase.yellowTime}
                                redTime={phase.total - phase.greenTime - phase.yellowTime - phase.startGreen}
                                onChange={(obj) => handleChange(idx, obj)}
                                startGreen={phase.startGreen}
                            />
                        </div>
                    ))}
                </div>
                <div className="config-footer">
                    <Button 
                        onClick={() => {
                            setIsEdit(false);
                            setIsShow(false);
                        }}
                        text={"Đóng lại"} className="button-light" />
                    <Button text={isEdit ? "Áp dụng" : "Chỉnh sửa"} onClick={handleSave} />
                </div>
            </div>
        </div>
    );
}
