import React, { useEffect, useRef, useState } from "react";
import { StationService } from "../../services/StationService";
import * as JSMpeg from 'jsmpeg-player';
import RTSPtoWEBPlayer from "./RTSPtoWEBPlayer";
import { prefix } from "@fortawesome/free-solid-svg-icons";

export const CameraView = ({ stationId, style, className, preFix }) => {
  const stationService = new StationService();
  const [wsUrl, setWSUrl] = useState('');
  const player = useRef();

  useEffect(() => {
    (async () => {
      const data = await stationService.getStreamCameraUrl(stationId, 1);
      if (data?.data?.wsUrl) {
        setWSUrl(data?.data?.wsUrl);
        console.log(`url: ${data?.data?.wsUrl}`)
        const player = new RTSPtoWEBPlayer({
          parentElement: document.getElementById(`${preFix}camera-${stationId}`),
        });
        player.load(data?.data?.wsUrl);
      }
    })()
  }, [stationId]);

  function play() {
    player.current.play();
  };

  function pause() {
    player.current.pause();
  };

  function stop() {
    player.current.stop();
  };

  function destroy() {
    player.current.destroy();
  };

  return (
    <div className={className} id={`${preFix}camera-${stationId}`} style={style}>

    </div>
  );
};
