import React, { useEffect, useRef } from 'react';
import RTSPtoWEBPlayer from './RTSPtoWEBPlayer';

function WebSocketVideoPlayer({ stationId, style, className }) {

  useEffect(() => {
    const player = new RTSPtoWEBPlayer({
        parentElement: document.getElementById("player"),
      });
      player.load('ws://171.244.38.120:8083/stream/cam1/channel/0/mse?uuid=cam1&channel=0');
  }, []);

  return (
    <div className={className} id='player' style={style}>
      {/* <video ref={videoRef} width="600" controls autoPlay /> */}
    </div>
  );
}

export default WebSocketVideoPlayer;