import React, { useContext } from "react";
import {
  ItemConfigFlow,
  ItemConfigActionTime,
  DropDownPolygonSetting,
  ItemText,
  ItemArrowDirection,
  DropDownPolygon,
  ListStationControlPanel,
  StationOverview,
  ListTrafficControlPanel,
  ItemConfigBackground,
} from "./components";
import {
  bin,
  floppyDisk,
  turnLeft,
  turnRight,
  goAhead,
  location as IconLocation,
  setting2,
  ArrowUp,
  sdkdBackground,
  polygon,
  zoomMap as zoomMapIcon,
  listParagraph,
  settingCollapse,
  settingExpend,
  trafficLight2,
  ShareIcon,
  closeModal,
  ArrowDownIcon,
  successIcon,
  NoteBook,
  Camera2,
} from "../../assets";
import GoogleMapReact from "google-map-react";
import "./components/styles";
import "react-circular-progressbar/dist/styles.css";
import * as ProtoCreator from "../ProtoCreator";
import {Col, Image, Row} from "react-bootstrap";
import { ModalConfig } from "../../components/ControlPannelPage/ModalConfig";
import { DropDownPolygonName } from "../../components/ControlPannelPage/DropDownPolygonName";
import { ListStationOption } from "../../components/ControlPannelPage/ListStationOption";
import { useState } from "react";
import { useEffect } from "react";
import { EditArrow } from "../../components/ControlPannelPage/EditArrow";
import { SideBarCollased } from "../../components/SideBarCollapsed";
import { StationService } from "../../services/StationService";
import { useLocation, useSearchParams } from "react-router-dom";
import ListMapIcon from "../../components/ControlPannelPage/ListMapIcon";
import { AppContext } from "../../contexts/AppContext";
import Cookies from "js-cookie";
import { config } from "../../configs/Config";
import { CameraView } from "../../components/ControlPannelPage/CameraView";
import { CameraViewDetail } from "../../components/ControlPannelPage/CameraViewDetail";
import { CameraConfig } from "../../components/ControlPannelPage/CameraConfig";
import WebSocketVideoPlayer from "../../components/ControlPannelPage/WebsocketVideoPlayer";

export const SignalType = {
  countdown: "COUNTER",
  trafficLight: "trafficLight",
  walking: "walking",
  railway: "railway",
  turnLeft: "LEFT",
  turnRight: "RIGHT",
  goAhead: "AHEAD",
  label: "label",
  text: "TEXT",
};

function InfoWindow({ title, backgroudColor }) {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      <div
        style={{
          opacity: "0.8",
          width: "104px",
          minHeight: "20px",
          backgroundColor: "#3751FF",
          padding: "8px",
          borderRadius: "4px",
        }}
      >
        <span
          style={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          {title}
        </span>
      </div>

      <div
        style={{
          width: "10px",
          left: "46px",
          bottom: "-10px",
          position: "absolute",
        }}
      >
        <img src={polygon} />
      </div>
    </div>
  );
}

function Pointer({ title, index, isSelect, onTap }) {
  return (
    <div
      onClick={onTap}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <InfoWindow
        backgroudColor={isSelect === true ? "green" : "#3751FF"}
        title={title}
      />
      <div className="mt-2">
        <img src={trafficLight2} />
      </div>
    </div>
  );
}

function openWindow(stationId, deviceId) {
  window.api.send('asynchronous-message',
    'createNewWindow',
    null,
    stationId,
    null,
    Cookies.get("token"),
    deviceId
  );
}

function ControlPanelPage() {
  const { socket, setSocket } = useContext(AppContext);
  const [isAll, setIsAll] = useState(0);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [currentStation, setCurrentStation] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [zoomMap, setZoomMap] = useState(15);
  const [centerMap, setCenterMap] = useState({
    lat: 10.850238978128816,
    lng: 106.80562239032668,
  });
  const [isShowBoardSetting, setIsShowBoardSetting] = useState(false);
  const [isShowAddStation, setIsShowAddStation] = useState(false);
  const [isShowTraffic, setIsShowTraffic] = useState(false);
  const [isShowDropDownDirection, setIsShowDropDownDirection] = useState(false);
  const [isShowDropDownName, setIsShowDropDownName] = useState(false);
  const [listDirectionArrow, setListDirectionArrow] = useState([]);
  const [listText, setListText] = useState([]);
  const [currentText, setCurrentText] = useState(-1);
  const [currentArrow, setCurrentArrow] = useState(-1);
  const [stationData, setStationData] = useState(null);
  const [stationState, setStationState] = useState(null);
  const [isWriteText, setIsWriteText] = useState(false);
  const [isShowSettingBackground, setIsShowSettingBackground] = useState(false);
  const [isShowTableConfigBackground, setIsShowTableConfigBackground] =
    useState(false);
  const [
    isShowTableConfigBackgroundSuccess,
    setIsShowTableConfigBackgroundSuccess,
  ] = useState(false);
  const [isShowTableConfigTimeAction, setIsShowTableConfigTimeAction] =
    useState(false);
  const [isShowTableConfigFlow, setIsShowTableConfigFlow] = useState(false);
  const [isShowModalConfig, setIsShowModalConfig] = useState(false);
  const [isShowCameraDetail, setIsShowCameraDetail] = useState(false);
  const [isShowCameraConfig, setIsShowCameraConfig] = useState(false);
  const [isShowCameraExpanded, setIsShowCameraExpanded] = useState(false);

  const [isShowEdit, setIsShowEdit] = useState(false);
  const [isShowEditArrow, setIsShowEditArrow] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const stationService = new StationService();
  const location = useLocation();
  const [listItems, setListItems] = useState([]);
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [deviceId, setDeviceId] = useState("");
  const [stationId, setStationId] = useState("");

  const [dataDevideSocket, updateDataDevideSocket] = useState(null);
  const [dataLog, updateDataLog] = useState("");
  let subStreamState = null;
  let subStreamStationRealtime = null;

  useEffect(() => {
    socket.websocket = new WebSocket(config.HOST_WS);

    socket.websocket.binaryType = "arraybuffer";
    socket.websocket.onopen = () => {
      if (Cookies.get("token") !== null) {
        console.log("SEND LOGIN");
        socket.websocket.send(
          ProtoCreator.CreateLoginMessage(Cookies.get("token"), searchParams.get("stationid"))
        );
      }
    };
    socket.websocket.onmessage = (evt) => {
      let mainMessage = proto.MainMessage.deserializeBinary(evt.data);
      console.log("MAIN", mainMessage?.toObject());
      if (
        mainMessage?.hasUsermessage() &&
        mainMessage.getUsermessage().hasDeviceonchangeusermessage()
      ) {
        const deviceData = mainMessage
          .getUsermessage()
          .getDeviceonchangeusermessage()
          .toObject();
        // console.log("DEVICE DATA", deviceData);
        console.log("Update DATA", deviceData);
        const deviceid = searchParams.get("deviceid") || deviceId;

        console.log(deviceid);
        const targetId = deviceData?.devicestateonchange?.deviceid;
        if (deviceid && targetId && deviceid === targetId) {
          updateDataDevideSocket(
            deviceData?.devicestateonchange?.update1second
          );
        }
        if (deviceData?.logupdate?.message) {
          updateDataLog(deviceData?.logupdate?.message);
        }
      }
    };
    socket.websocket.onclose = (e) => {
      console.log("CLOSE SOCKET");
    };
    // websocket onerror event listener
    socket.websocket.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      socket.websocket?.close();
    };

    setSocket(socket);

    let interval = setInterval(() => {
      console.log(
        `Require stream with interval: ${searchParams.get("stationid")}`
      );
      stationService.openStream(searchParams.get("stationid")).then();
    }, 5000);

    return () => {
      if (subStreamState) subStreamState.unsubscribe();
      if (subStreamStationRealtime) subStreamStationRealtime.unsubscribe();

      socket.websocket?.close();
      console.log(`close require stream`);
      if (interval != null) {
        clearInterval(interval);
      }
    };
  }, [deviceId]);

  useEffect(async () => {
    const data = await stationService.getStations();
    if (data?.data?.stations) {
      setData(data?.data?.stations);
      setFilterData(data?.data?.stations);
    }
  }, []);

  useEffect(() => {
    if (data.length) {
      const stationid = searchParams.get("stationid");
      if (stationid) {
        const index = data.findIndex((item) => item.id === stationid);

        if (isShowTraffic) {
          setCurrentStation(index);
        } else {
          setTimeout(() => {
            onSelectItem(index);
          }, 500);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    console.log("ZOADOUI", searchParams.get("tab") === "traffic");
    if ((searchParams.get("tab") === "traffic") != isShowTraffic) {
      setIsShowTraffic(searchParams.get("tab") === "traffic");
    }
  }, [searchParams]);

  const onTapCurrentArrow = (index) => {
    setCurrentText(-1);
    setCurrentArrow(index);
  };

  const onAddText = async (value) => {
    try {
      const result = await stationService.addMapItem({
        stationId: data[currentStation]?.id,
        type: SignalType.text,
        phase: 0,
        x: 450,
        y: 300,
        rotate: 0,
        scale: 1,
        content: value,
      });
      setListItems(result.data.mapItems);
    } catch (error) { }
    setIsShowDropDownName(false);
  };

  const onAddDirectionArrow = async (type) => {
    try {
      const result = await stationService.addMapItem({
        stationId: data[currentStation]?.id,
        type: type,
        phase: 0,
        x: 450,
        y: 300,
        rotate: 0,
        scale: 1,
        content: "",
      });
      setListItems(result.data.mapItems);
    } catch (error) { }
    setIsShowDropDownDirection(false);
  };

  const onSelectItem = (idx) => {
    // console.log(data[idx].id);
    // socket.websocket.send(ProtoCreator.CreateGetStationUserRequest(data[idx].id));

    setDeviceId(data[idx].deviceId);
    setSearchParams({
      stationid: data[idx].id,
      deviceid: data[idx].deviceId,
    });

    setCurrentStation(idx);
    setCenterMap({
      lat: data[idx].stationLat,
      lng: data[idx].stationLong,
    });
    setZoomMap(20);
  };

  const onSetItemOnList = (idx, value) => {
    // let tempData = [...data];
    // if (value === true) {
    //     tempData[idx].online = true;
    //     filterData[idx] = null;
    // } else {
    //     tempData[idx].online = false;
    //     filterData[idx] = data[idx];
    // }
    // setData(tempData);
    // setFilterData([...filterData]);
  };

  const onSelectOpionAll = (value, textSearch) => {
    if (value === 0) {
      setIsAll(value);
      setFilterData(
        data.filter((item) => item.stationName.includes(textSearch))
      );
    } else {
      setIsAll(value);
      setFilterData(
        data.filter(
          (item) =>
            item.online === (value === 1) &&
            item.stationName.includes(textSearch)
        )
      );
    }
  };

  const handleApiLoaded = (map, maps) => {
    new maps.Marker({
      map: map,
      animation: maps.Animation.DROP,
      position: { lat: -27.63453, lng: 153.0387 },
    });
  };

  const onShowSettingBoard = (value, idx) => {
    setIsShowBoardSetting(value);
    setCurrentStation(idx);
  };

  const handleSearch = (value) => {
    onSelectOpionAll(isAll, value);
    setTextSearch(value);
  };

  const handleSelectItemMap = (value) => {
    setIsShowEdit(true);
    onTapCurrentArrow(value);
  };

  const handleRemoveItem = async () => {
    try {
      const result = await stationService.deleteMapItem(currentArrow);
      setListItems(result.data.mapItems);
    } catch (error) { }
    setIsShowEdit(false);
  };

  const handleChangeItem = async (data) => {
    try {
      const item = listItems.find((x) => x.id === currentArrow);
      const result = await stationService.updateMapItem({
        itemId: item.id,
        type: item.type,
        x: item.position.x,
        y: item.position.y,
        rotate: item.position.rotate,
        scale: item.position.scale,
        content: data.content,
        phase: data.phase,
      });
      console.log(result.data);
      setListItems(result.data.mapItems);
    } catch (error) { }
    setIsShowEditArrow(false);
    setIsShowEdit(false);
  };

  const handleSaveItem = async () => {
    try {
      const item = listItems.find((x) => x.id === currentArrow);
      const result = await stationService.updateMapItem({
        itemId: item.id,
        type: item.type,
        x: item.position.x,
        y: item.position.y,
        rotate: item.position.rotate,
        scale: item.position.scale,
        content: item.content,
        phase: item.phase,
      });
      setListItems(result.data.mapItems);
    } catch (error) { }
    setIsShowEdit(false);
  };

  const handleSetActiveTime = async (from, to) => {
    console.log(`set time, from: ${from}, to: ${to}`);

    if (from && to) {
      const result = await stationService.setActiveTime({
        stationId: data[currentStation]?.id,
        start: from.length === 4 ? "0" + from : from,
        end: to.length === 4 ? "0" + to : to,
      });

      if (result?.data?.success) {
        setIsShowTableConfigBackgroundSuccess(true);
      }
    }

    // if (from && to) {
    //     let fromH = parseInt(from.split(':')[0]);
    //     let fromM = parseInt(from.split(':')[1]);
    //     let toH = parseInt(to.split(':')[0]);
    //     let toM = parseInt(to.split(':')[1]);
    //     socket.websocket.send(
    //         ProtoCreator.SetActiveTimeUserRequest(socket.deviceId, fromH, fromM, toH, toM)
    //     );
    // }

    setIsShowTableConfigTimeAction(false);
    setTimeout(() => {
      setIsShowTableConfigBackgroundSuccess(false);
    }, 2000);
  };

  const onConfirm = async (data) => {
    const result = await stationService.addStation(data);
    if (result?.data?.stations) {
      setData(result?.data?.stations);
      setFilterData(result?.data?.stations);
    }
    return true;
  };

  let settingBoard = isShowBoardSetting && (
    <div className="item-setting-station-controlpanelpage">
      {/* Close */}
      <Image
        onClick={() => setIsShowBoardSetting(false)}
        className="close-modal"
        src={closeModal}
        alt=""
      />
      <StationOverview
        id={data[currentStation].id}
        deviceId={data[currentStation].hardwareId}
        index={currentStation}
        state={data[currentStation].online}
        station={data[currentStation]}
        stationName={data[currentStation].stationName}
        onClose={onShowSettingBoard}
        handleToggle={(on) => {
          let tempState = data[currentStation].online;
          console.log(`on off: ${on.target.checked}`);
          let isOn = on.target.checked;
          socket.websocket.send(
            ProtoCreator.CreateControlLaneUserMessage(socket.deviceId, isOn, 0)
          );
          onSetItemOnList(currentStation, !tempState);
        }}
        allowClose={false}
        stationData={stationData}
        stationState={stationState}
        isStatusOutput={isShowTraffic}
        dataDevideSocket={dataDevideSocket}
      />
    </div>
  );

  return (
    <>
      <div
        className={`controlpannel-container ${isShowTraffic ? "flex-row-reverse" : null
          }`}
      >
        <SideBarCollased
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          children={
            isShowTraffic ? (
              <ListTrafficControlPanel
                id={searchParams.get("stationid")}
                setIsShowModalConfig={() => setIsShowModalConfig}
                setIsShowCameraDetail={() => setIsShowCameraDetail}
                dataDevideSocket={dataDevideSocket}
              />
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="d-flex">
                    <img src={listParagraph} />
                    <span className="controlpannel-hero">Danh sách trạm</span>
                  </div>
                  <img
                    src={settingCollapse}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsExpanded(false);
                    }}
                  />
                </div>
                <ListStationOption
                  onConfirm={onConfirm}
                  onAddStation={() => {
                    setIsShowAddStation(true);
                  }}
                  isChoosen={isAll}
                  onSelect={(value) => onSelectOpionAll(value, textSearch)}
                  value={textSearch}
                  setValue={handleSearch}
                />
                <ListStationControlPanel
                  highlightText={textSearch}
                  currentStation={currentStation}
                  onSelectItem={onSelectItem}
                  listData={filterData}
                  onSetItemList={onSetItemOnList}
                />
              </>
            )
          }
        />

        <div
          className="content-table-controlpanelpage"
          style={{
            width: isExpanded ? "986px" : "1280px",
            position: "relative",
          }}
        >
          <div className="position-relative w-100 h-100">
            {/* Modal Config */}
            {isShowTraffic && (
              <ModalConfig
                stationId={searchParams.get("stationid")}
                isShow={isShowModalConfig}
                setIsShow={setIsShowModalConfig}
              />
            )}

            {isShowTraffic && (
              <CameraViewDetail
                stationId={searchParams.get("stationid")}
                isShow={isShowCameraDetail}
                setIsShow={setIsShowCameraDetail}
                setIsClose={setIsShowCameraExpanded}
              />
            )}

            {isShowTraffic && (
                <CameraConfig
                    stationId={searchParams.get("stationid")}
                    isShow={isShowCameraConfig}
                    setIsShow={setIsShowCameraConfig}
                    setIsClose={setIsShowCameraExpanded}
                />
            )}

            {!isShowTraffic &&
              (isAll === 0 ? data : filterData).length === 0 && (
                <div className="overlap-nodata">Không tìm thấy dữ liệu</div>
              )}
            {isShowTraffic ? (
              <>
                <div
                  style={{
                    borderRadius: "8px",
                    width: "100%",
                    height: "100%",
                    filter: "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.04))",
                  }}
                >
                  <img
                    src={backgroundUrl || sdkdBackground}
                    className="bg-traffic"
                  />
                </div>
                {settingBoard}
                <div
                  className="pin-content"
                  onClick={() => {
                    onShowSettingBoard(true, currentStation);
                  }}
                  style={{ display: isShowBoardSetting ? "none" : "flex" }}
                >
                  <span className="pin-content-name">Trạng thái ngõ ra</span>
                  <ArrowUp color={"#3751FF"} />
                </div>
                <ListMapIcon
                  setBackgroundUrl={setBackgroundUrl}
                  id={data[currentStation]?.id}
                  onTab={handleSelectItemMap}
                  listItems={listItems}
                  setListItems={setListItems}
                  dataDevideSocket={dataDevideSocket}
                />
                {isShowTableConfigFlow && (
                  <ItemConfigFlow
                    onTapCancel={() => {
                      setIsShowTableConfigFlow(false);
                    }}
                    onTapDone={(lane) => {
                      setIsShowTableConfigFlow(false);
                      setIsShowTableConfigBackgroundSuccess(true);
                      setTimeout(() => {
                        setIsShowTableConfigBackgroundSuccess(false);
                      }, 1000);
                      lane = lane === 0 ? 9 : lane;
                      socket.websocket.send(
                        ProtoCreator.CreateControlLaneUserMessage(
                          socket.deviceId,
                          true,
                          lane
                        )
                      );
                    }}
                    numPhase={stationState?.stationInfo?.numPhase}
                    lane={stationState?.control?.lane}
                  />
                )}
                {isShowTableConfigTimeAction && (
                  <ItemConfigActionTime
                    idStation={data[currentStation]?.id}
                    from={"11:20"}
                    to={"13:40"}
                    onTapCancel={() => {
                      setIsShowTableConfigTimeAction(false);
                    }}
                    onTapDone={handleSetActiveTime}
                  />
                )}
                {isShowTableConfigBackground && (
                  <ItemConfigBackground
                    onTapCancel={() => {
                      setIsShowTableConfigBackground(false);
                    }}
                    onTapDone={async (url) => {
                      const result = await stationService.setBackgroundImage({
                        stationId: data[currentStation].id,
                        imageUrl: process.env.REACT_APP_API_BASE_URL + url,
                      });

                      setBackgroundUrl(result.data.imageUrl);

                      setIsShowTableConfigBackground(false);
                      setIsShowTableConfigBackgroundSuccess(true);
                      setTimeout(() => {
                        setIsShowTableConfigBackgroundSuccess(false);
                      }, 2000);
                    }}
                  />
                )}
                {isShowTableConfigBackgroundSuccess && (
                  <div className="success-content">
                    <img src={successIcon} />
                    <span className="success-text">
                      Đã thiết lập thành công
                    </span>
                  </div>
                )}
                {isShowEditArrow && (
                  <EditArrow
                    data={listItems.find((x) => x.id === currentArrow)}
                    onCancel={() => setIsShowEditArrow(false)}
                    onSubmit={handleChangeItem}
                  />
                )}

                {/* dropdown selection for tool */}
                <div
                  className={`${isShowDropDownName ? "d-flex" : "d-none"
                    } polygon-content-name`}
                >
                  <DropDownPolygonName onClick={onAddText} />
                </div>
                <div
                  className={`${isShowDropDownDirection ? "d-flex" : "d-none"
                    } polygon-content`}
                >
                  <DropDownPolygon
                    listPrefix={[turnLeft, turnRight, goAhead]}
                    listSuffix={["A", "D", "W", ""]}
                    rightPolygon={"78px"}
                    listOption={["Quẹo trái", "Quẹo phải", "Đi thẳng", "Countdown"]}
                    addAction={onAddDirectionArrow}
                  />
                </div>
                <div
                  className={`${isShowSettingBackground ? "d-flex" : "d-none"
                    } polygon-content`}
                >
                  <DropDownPolygonSetting
                    rightPolygon={"15px"}
                    listOption={["Thiết lập hình nền", "Thiết lập thời gian"]}
                    listAction={[
                      () => {
                        setIsShowSettingBackground(false);
                        setIsShowTableConfigBackground(true);
                      },
                      () => {
                        setIsShowSettingBackground(false);
                        setIsShowTableConfigTimeAction(true);
                      },
                    ]}
                  />
                </div>
                {/* camera expanded */}
                <div
                  className={`${isShowCameraExpanded ? "d-flex" : "d-none"
                    } polygon-content`}
                  style={{
                    right: "auto",
                    left: "34px",
                  }}
                >
                  <div className="dropdown-polygon" style={{ width: "auto" }} >
                    <div className="dropdown-polygon-content" style={{ padding: "12px", height: "fit-content", border: "12px", width: "fit-content" }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "fit-content",
                        gap: "12px",
                      }}>
                        {/* <CameraView preFix={"preview"} style={{height: '288px', width: '384px', border: '1px solid blue'}}
                                    stationId={searchParams.get("stationid")}/> */}
                        <CameraView style={{ width: '480px', height: "320px", }} preFix={"preview-"} stationId={searchParams.get("stationid")} />
                        {/* <WebSocketVideoPlayer style={{height: '288px', width: '384px', border: '1px solid blue'}}
                                    stationId={searchParams.get("stationid")}/>             */}
                        <Row>
                          <Col>
                            <button className="button-base" style={{width: '100%'}} onClick={() => {
                              setIsShowCameraConfig(!isShowCameraConfig)
                            }}>Thiết lập
                            </button>
                          </Col>
                          <Col>
                            <button className="button-base" style={{width: '100%'}} onClick={() => {
                              setIsShowCameraDetail(!isShowCameraDetail)
                            }}>Chi tiết
                            </button>
                          </Col>
                        </Row>
                      </div>

                    </div>
                  </div>
                </div>
                {/* tool for set item in map */}
                {/* left tool */}
                {isShowEdit && (
                  <div className="controlpanel-edit">
                    <div
                      className="controlpanel-edit-content"
                      onClick={() => {
                        setIsShowEditArrow(true);
                      }}
                    >
                      <span className="controlpanel-edit-text">Chỉnh sửa</span>
                    </div>
                    <div className="icon-setting" onClick={handleRemoveItem}>
                      <img src={bin} />
                    </div>
                    <div className="icon-setting" onClick={handleSaveItem}>
                      <img src={floppyDisk} />
                    </div>
                  </div>
                )}

                {/* left tools */}
                <div className="right-tool" style={{
                  right: "auto",
                  left: "35px",
                }}>
                  {/* option camera */}
                  <div
                    className={`icon-setting ${isShowCameraExpanded ? "active" : ""
                      }`}
                    onClick={() => {
                      setIsShowCameraExpanded(!isShowCameraExpanded)
                    }}
                  >
                    <Camera2 color={isShowDropDownName ? "#FFFFFF" : "#212121"} />
                  </div>
                </div>

                {/*  right tool */}
                <div className="right-tool">
                  {/* Edit */}
                  <div
                    className={`icon-setting ${isShowDropDownName ? "active" : ""
                      }`}
                    onClick={() => {
                      setIsShowDropDownDirection(false);
                      setIsShowSettingBackground(false);
                      setIsShowDropDownName(!isShowDropDownName);
                    }}
                  >
                    <NoteBook
                      color={isShowDropDownName ? "#FFFFFF" : "#212121"}
                    />
                  </div>
                  {/* location direction  */}
                  <div
                    className="icon-location"
                    onClick={() => {
                      setIsShowDropDownDirection(!isShowDropDownDirection);
                      setIsShowSettingBackground(false);
                      setIsShowDropDownName(false);
                    }}
                  >
                    <img src={IconLocation} />
                    <ArrowDownIcon />
                  </div>
                  {/* Settings */}
                  <div
                    className="icon-setting"
                    onClick={() => {
                      setIsShowDropDownDirection(false);
                      setIsShowSettingBackground(!isShowSettingBackground);
                      setIsShowDropDownName(false);
                    }}
                  >
                    <img src={setting2} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: config.MAP_KEY,
                    MAP_KEY: config.MAP_KEY,
                  }}
                  // defaultCenter={centerMap}
                  defaultZoom={15}
                  options={{
                    disableDefaultUI: true,
                  }}
                  center={centerMap}
                  zoom={zoomMap}
                  yesIWantToUseGoogleMapApiInternals={false}
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                  }
                >
                  {data.map((item, i) => {
                    return (
                      <Pointer
                        key={i}
                        lat={item?.stationLat}
                        lng={item?.stationLong}
                        title={item?.stationName}
                        index={i}
                        isSelect={currentStation === i}
                        onTap={() => {
                          onShowSettingBoard(true, i);
                        }}
                      />
                    );
                  })}
                </GoogleMapReact>
                {currentStation !== null && (
                  <>
                    <div
                      onClick={() => {
                        setSearchParams({
                          stationid: data[currentStation].id,
                          deviceid: data[currentStation].deviceId,
                          tab: "traffic",
                        });
                      }}
                      className="zoom-icon"
                    >
                      <img src={zoomMapIcon} />
                    </div>
                    <div
                      onClick={() => {
                        let platform = Cookies.get('platform');
                        if (platform === 'app') {
                          openWindow(data[currentStation].id, data[currentStation].deviceId)
                        } else {
                          window.open(
                            location?.pathname +
                            location?.search +
                            "&tab=traffic&hidemenu=true",
                            "_blank"
                          )
                        }
                      }}
                      className="item-search-map-bar-colapse-controlpanelpage"
                    >
                      <ShareIcon />
                    </div>
                  </>
                )}

                {settingBoard}
              </>
            )}
          </div>

          {/* Timestamp */}
          {isShowTraffic && (
            <div className="traffic-timestamp">
              <div className="traffic-timestamp-text">
                {"DEVICE LOG: " + dataLog}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ControlPanelPage;
