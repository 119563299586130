import { useEffect, useState } from "react"
import { Button } from "../shared/Button"
import { InputRange } from "../shared/InputRange"
import { Select } from "../shared/Select"
import './chart-content.css'

export const ChartContent = ({ data, setData, currentForm, setCurrentForm }) => {
    const [isEdit, setIsEdit] = useState(false);
    const options = Array.from({length: 32}, (_, i) => ({ key: i, text: 'Biểu mẫu ' + (i + 1)}));
    const [totalTime, setTotalTime] = useState(data?.total);

    useEffect(() => {
        setTotalTime(data?.total);
    }, [data?.total]);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         handleUpdateTime(totalTime);
    //     }, 800);
    //
    //     return () => clearTimeout(timer);
    // }, [totalTime])

    const handleUpdateTime = (totalTime) => {
        if (data) {
            data.total = totalTime;
            for (let i = 0; i < data.phaseCounters?.length; i++) {
                data.phaseCounters[i].total = totalTime;
                if (totalTime < getMaxPhase()) {
                    data.phaseCounters[i].startGreen = 0;
                    data.phaseCounters[i].greenTime = totalTime - 3;
                    data.phaseCounters[i].yellowTime = 3;
                }
            }
            setData({...data});
        }
    }

    const getMaxPhase = () => {
        return Math.max(...data?.phaseCounters?.map(phase => phase.startGreen + phase.greenTime + phase.yellowTime));
    }

    const handleChangeTime = (e) => {
        const minPhase = 3;
        let res = 3;
        if (parseInt(e.target.value) < minPhase) {
            setTotalTime(minPhase);
            res = minPhase;
        } else if (parseInt(e.target.value) >= minPhase) {
            res = parseInt(e.target.value);
            setTotalTime(res);
        }
        handleUpdateTime(res);
    }

    const handleApplyTime = () => {
        data.total = totalTime;
        for (let i = 0; i < data.phaseCounters?.length; i++) {
            data.phaseCounters[i].total = totalTime;                
            if (totalTime < getMaxPhase()) {
                data.phaseCounters[i].startGreen = 0;
                data.phaseCounters[i].greenTime = totalTime;
                data.phaseCounters[i].yellowTime = 0;
            }
        }
        
        setData(data);
        setIsEdit(false);
    }

    return (<div className="chart-content">
        <div className="chart-content-top">
            <div className="chart-header">
                <div>
                    <span className="color-text-base font-14 text-medium mr-12">Biểu mẫu</span>
                    <Select className="mw-200" options={options} value={currentForm} onChange={setCurrentForm} />
                </div>
                {!isEdit ? <Button className="p-10-20" text={'Chỉnh sửa'} onClick={() => setIsEdit(true)} />
                : <div>
                    <Button className="p-10-20 button-cancel w-auto" text={'Hủy bỏ'} onClick={() => setIsEdit(false)} />
                    <Button className="p-10-20 ml-3" text={'Lưu lại'} onClick={handleApplyTime} />
                </div>}
            </div>
            <div className="chart-content-main">
                <div className="activity-header py-2 justify-content-start">
                    <nav className="activity-text mr-3">Số pha : <span className="color-green">{data?.numPhase}</span></nav>
                    <nav className="activity-text">Tổng chu kỳ :{' '}
                        {isEdit ? <>
                            <input type="number" className="schedule-input" value={totalTime} onChange={handleChangeTime} />
                            <span className="color-green"> giây</span>
                        </> : <span className="color-green">{data?.total} giây</span>}
                    </nav>
                </div>
                <div className="h-406 py-3 pr-100 activity-content config-content scrollbar-homepage scrollbar-custom-homepage">
                    {data?.phaseCounters?.map((phase, idx) => 
                    {
                      if (idx >= data?.numPhase) {
                        return;
                      }
                      return (
                        <div key={idx} className="activity-item">
                          <span className="text-medium">Pha {idx + 1}</span>
                          <InputRange
                            key={idx}
                            startGreen={phase.startGreen}
                            greenTime={phase.greenTime}
                            yellowTime={phase.yellowTime}
                            redTime={
                              phase.total -
                              phase.greenTime -
                              phase.yellowTime -
                              phase.startGreen
                            }
                            onChange={(obj) => {
                              // data.phaseCounters[idx].startGreen = obj.greenTime;
                              // data.phaseCounters[idx].startYellow = obj.greenTime + obj.yellowTime;
                              // data.phaseCounters[idx].startRed = obj.greenTime + obj.yellowTime;
                              data.phaseCounters[idx].startGreen =
                                obj.startGreen;
                              data.phaseCounters[idx].greenTime = obj.greenTime;
                              data.phaseCounters[idx].yellowTime =
                                obj.yellowTime;
                              setData(data);
                            }}
                          />
                        </div>
                      );
                    }
                    )}
                </div>
            </div>
        </div>
    </div>
  )
}
