import './styles';

import React, { useState } from 'react';
import { useEffect } from 'react';
import { TrafficHeader } from '../../../components/ControlPannelPage/TrafficHeader';
import { ItemTraffic } from '../../../components/ControlPannelPage/ItemTraffic';
import { TrafficBottom } from '../../../components/ControlPannelPage/TrafficBottom';
import { StationService } from '../../../services/StationService';


function ListTrafficControlPanel({ id, setIsShowModalConfig, setIsShowCameraConfig, dataDevideSocket }) {
    const [stationSetting, setStationSetting] = useState(null);
    const stationService = new StationService();

    useEffect(() => {
        console.log(id);    
        stationService.getStationSetting(id).then(data => {
            console.log(data)
            setStationSetting(data.data);
        });
    }, []);

    const updateNamePhase = async (data) => {
        try {
            const result = await stationService.updatePhase(data);            
            setStationSetting(result.data);
        } catch (error) {}
    }

    const syncTime = async () => {
        try {
            const result = await stationService.syncTime(id);           
            console.log(result);
        } catch (error) {}
    }

    return (
        <div style={{ width: '100%' }}>
            <TrafficHeader data={stationSetting}
                           setIsShowModalConfig={setIsShowModalConfig}
                           setIsShowCameraConfig={setIsShowCameraConfig}
                           syncTime={syncTime}
                           dataDevideSocket={dataDevideSocket} />
            <div className="scrollbar-homepage scrollbar-custom-homepage traffic-list">
                {stationSetting?.phaseData?.map((item, idx) => (
                    <ItemTraffic
                        stationId={id}
                        key={idx}
                        idx={idx}
                        name={item.phaseName}
                        // type={type}
                        count={item.counter}
                        walking={item.walkingSignal}
                        phaseNum={item.phaseNum}
                        updateNamePhase={updateNamePhase}
                        dataSocket={dataDevideSocket?.phasestatesList[idx]}
                        // setting={stationState?.stationInfo?.signSetting[idx]}
                    />
                ))}
            </div>
            <TrafficBottom 
                stationId={id}
                dataDevideSocket={dataDevideSocket}
            />
        </div>
    );
}
export { ListTrafficControlPanel };