import { Camera2, GoCrossTraffic, Train } from '../../assets';
import { Button } from '../shared/Button';
import './traffic-header.css';

export const TrafficHeader = ({ data, setIsShowModalConfig, setIsShowCameraConfig, syncTime, dataDevideSocket }) => {
    return (
        <div className="mb-3">
            <nav className="traffic-title">{data?.stationName}</nav>
            <div className="d-flex align-items-center justify-content-between">
                <nav className="traffic-text">Thời gian CPU <span className="text-blue">{dataDevideSocket?.cputime}</span></nav>
                <Button text={"Đồng bộ"} onClick={syncTime} className="button-small" />
            </div>
            <div className="traffic-sign">
                <div className={`traffic-${dataDevideSocket?.iswalkingsignalenable ? 'green' : 'gray'}`}>
                    <GoCrossTraffic color={dataDevideSocket?.iswalkingsignalenable ? "#fff" : "#8C8C8C"} />
                </div>
                <div className={`traffic-${dataDevideSocket?.israilwaysignalenable ? 'green' : 'gray'}`}>
                    <Train color={dataDevideSocket?.israilwaysignalenable ? "#fff" : "#8C8C8C"} />
                </div>
                <div className="traffic-gray">
                    <Camera2 color="#8C8C8C" />
                </div>
            </div>
            <div className="text-center">
                <Button onClick={setIsShowModalConfig(true)} text={'Cấu hình biểu mẫu'} />
                {/* <Button onClick={setIsShowCameraConfig(true)} text={'Camera'} /> */}
            </div>
        </div>
    );
}
